import { Component } from 'react';
import PropTypes from 'prop-types';
import PhoneNumber from "../../../utilities/PhoneNumber";
import Button from "../../../utilities/Button";
import FontAwesome from "../../../utilities/FontAwesome";
import { observer } from 'mobx-react';
import classNames from "classnames";
import styles from './styles/LocationBlockItem.module.scss';
import {StoreContext} from "../../../../stores/StoreLoader";

@observer
class LocationBlockItem extends Component {
    static contextType = StoreContext;
    static propTypes = {
        location: PropTypes.shape({
            address: PropTypes.string,
            address2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zip: PropTypes.string,
            phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            hours: PropTypes.object,
        }),
        onClick: PropTypes.func,
        className: PropTypes.string,
        attribution: PropTypes.string,
    };

    render() {
        const mapInsertClassName = classNames({
            [styles.mapInsert]: true,
            [this.props.className]: this.props.className,
        });
        const insertSectionClassName = classNames({
            [styles.insertSection]: true,
            ["notranslate"]: true,
        });

        let addressText = this.props.location.address;
        if (this.props.location.address2) {
            addressText += ` ${this.props.location.address2}`;
        }
        if (this.props.location.city) {
            addressText += ` ${this.props.location.city}`;
        }
        if (this.props.location.zip) {
            addressText += `, ${this.props.location.zip}`;
        }

        let phone = '';
        if (this.props.location &&
            this.props.location.phone) {
            phone = <>
                <FontAwesome prefix={"fas"} name={'fa-phone-alt'}/>
                <PhoneNumber number={this.props.location.phone} isLinked={true}/>
            </>
        }

        return (
            <div className={mapInsertClassName}>
                {this.props.onClick && <Button onClick={this.props.onClick} aria-label={'Close Map Insert'}>
                    <FontAwesome ariaHidden={true} prefix={'fas'} name={'fa-times'}/>
                </Button>}
                <div>
                    <img
                        src={this.props.location.organization.logo}
                        alt={`${this.props.location.organization.title} logo`}
                        title={this.props.location.organization.title}
                    />
                </div>
                {addressText && <div className={insertSectionClassName}>
                    <FontAwesome prefix="fas" name={'fa-map-marker-alt'}/>
                    <address>
                        {addressText}
                    </address>
                </div>}
                {phone && <div className={insertSectionClassName}>
                    {phone}
                </div>}
            </div>
        )
    }
}

export default LocationBlockItem